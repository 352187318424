import React from 'react';

// location is implicit prop passed by Switch and Route in main.js
// Use image from https://youtu.be/KbycKXUtrVk?t=14 as background?
const Products = () => (
  <div className="mainContainer">
    <div className="bodyContainer">
      <h1>PRODUCTS</h1>

    </div>
  </div>
)

export default Products;
