import React from 'react';

import '../css/Header.css';

export const Header = () => {
  return (
    <div className="headerContainer">
      <div id="nav">
        <span id="leftContainer"><a href="/">HOME</a></span>
        <span id="rightContainer">
          <a className="navLink" href="/teaching">TEACHING</a>
          <a className="navLink" href="/music">MUSIC</a>
        </span>
      </div>
    </div>
  )
}

export const MusicHeader = () => {
  return (
    <div className="headerContainer">
      <div id="nav">
        <span id="leftContainer"><a className="navLink" href="/music">MUSIC</a></span>
        <span id="rightContainer"><a className="navLink" href="/arrangements">ARRANGEMENTS</a></span>
      </div>
    </div>
  )
}
