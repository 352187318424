import React from 'react';

class LessonPolicy extends React.Component {
  handleDownload() {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/static/lessonPolicy.pdf`;
    link.download = 'lessonPolicy.pdf';
    link.click();
  };

  render() {
    return (
      <div className="fullPageContainer" style={{ overflow: 'scroll', paddingTop: 15, paddingBottom: 15 }}>
        <h1>LESSON TERMS AND AGREEMENT</h1>

        <div className="buttonContainer">
          <button onClick={this.handleDownload}>Download as PDF</button>
        </div>

          <img
            src={`${process.env.PUBLIC_URL}/static/lessonPolicy.png`}
            alt="Lesson Policy"
          />
        {/*
          Displays wonkily with editor
          <iframe
            title="Lesson Policy"
            src={`${process.env.PUBLIC_URL}/static/lessonPolicy.pdf`}
            width="100%"
            height="1000"
          /> */}
      </div>
    )
  }
}

export default LessonPolicy;
