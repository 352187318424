import React from 'react';
import '../../css/Music.css';

class Music extends React.Component {
  render() {
    return(
      <div className="mainContainer">
        <div className="bodyContainer">
          <h1>MUSIC</h1>
          <p>
            Lawrence Luo (b. 1996) is a composer, arranger, and multi-instrumentalist based in Boston & San Francisco with over a decade of experience composing and arranging music.
            He has won NACUSA and MTAC awards for his contemporary classical compositions, and has had his arrangements for chamber ensembles, pop-fusion, and a cappella genres performed in-concert dozens of times.
            Please email <a href="mailto:luolawrence1@gmail.com">luolawrence1@gmail.com</a> for inquiries.
          </p>

          <p>Currently affiliated with:</p>
          <ul>
            <li><a target="_blank" rel="noopener noreferrer" href="https://open.spotify.com/artist/0HVbw8ntLBVDL9fpJYMljZ?si=FtekFl36RtuUTf78rNBbBA">Alex Togashii</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/QuarterAfterBand/">Quarter After</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/buinachord2">The A Cappella Collective</a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCUmsLPXkFTBv5TpdUuedSdw">BU In Achord</a></li>
          </ul>

          <button className="navLink" onClick={() => window.location = '/arrangements'}>View A Cappella arrangements</button>
        </div>
      </div>
    )
  }
}

export default Music;


// Not used: For embedding youtube videos
// import ReactPlayer from 'react-player';
// <ReactPlayer
//   className="videoPlayer"
//   url='https://www.youtube.com/watch?v=3W09RlqTy8I'
//   volume={1}
//   playing={false}
//   controls={true}
//   light={true}
// />
