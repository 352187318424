import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Home, Music, Teaching, LessonPolicy, Products, Resume, Arrangements, Page404 } from './Pages';

// Docs: https://github.com/remix-run/react-router/blob/main/docs/getting-started/tutorial.md
// NavLink - allows styling links conditionally based on current Route

const Navigator = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="music" element={<Music />} />
      <Route path="teaching" element={<Teaching />} />
      <Route path="lessonPolicy" element={<LessonPolicy />} />
      <Route path="products" element={<Products />} />
      <Route path="resume" element={<Resume />} />
      <Route path="arrangements" element={<Arrangements />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}

const Main = () => {
  return (
    <Navigator />
  )
}

export default Main;
