import React from 'react';

import '../css/Footer.css';

export const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="social-links">
        <a href="mailto:luolawrence1@gmail.com"><i className="fa fa-envelope-square" aria-hidden="true" /><span className="sr-only">Email</span></a>
        {/* <a href="https://linkedin.com/in/lawrenceluo8" rel="noopener noreferrer" target="_blank"><i className="fa fa-linkedin-square" aria-hidden="true" /></a> */}
        <a href="https://github.com/lawrluor" rel="noopener noreferrer" target="_blank"><i className="fa fa-github-square" aria-hidden="true" /><span className="sr-only">Github</span></a>
        <a href="https://www.instagram.com/notesfromlaw" rel="noopener noreferrer" target="_blank"><i className="fa fa-instagram" aria-hidden="true" /><span className="sr-only">Instagram</span></a>
      </div>
    </div>
  )
}

export const MusicFooter = () => {
  return (
    <div className="footerContainer">
      <div className="social-links">
        <a href="mailto:luolawrence1@gmail.com"><i className="fa fa-envelope-square" aria-hidden="true" /><span className="sr-only">Email</span></a>
        <a href="https://www.instagram.com/notesfromlaw" rel="noopener noreferrer" target="_blank"><i className="fa fa-instagram" aria-hidden="true" /><span className="sr-only">Instagram</span></a>
      </div>
    </div>
  )
}