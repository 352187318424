import React from 'react';
import '../../css/Arrangements.css';
import data from '../../data/data.json';
import useMediaQuery from '../../hooks/useMediaQuery';
import ImageOverlay from '../ImageOverlay/ImageOverlay';

function Arrangements() {
	const isMobile = useMediaQuery("(max-width: 800px)");

	return (
		<div className="mainContainer">
			<div className="bodyContainer">
				<h1>FEATURED ARRANGEMENTS</h1>

				<div className="cardContainer" style={{ "justifyContent": isMobile ? "center" : "flex-start" }}>
					{data.arrangements.map((arrangement) => {
						if (arrangement.Ensemble !== "Mixed Choir/A Cappella" && arrangement.Visibility !== "Public")
							return null;

						return (
							<ImageOverlay
								key={arrangement.Title}
								arrangement={arrangement}
								src={`${process.env.PUBLIC_URL}/static/img/albumArt/${arrangement.ArtFile}`}
								alt={arrangement.Title}
							/>
						)
					})}
				</div>

				{/* {!isMobile &&
				<table>
					<thead>
						<tr>
							<th>TITLE</th>
							<th>AS PERFORMED BY</th>
							<th>ENSEMBLE</th>
							<th>PURCHASE</th>
							<th>LISTEN</th>
						</tr>
					</thead>
					<tbody>
						{data.arrangements.map((arrangement) => {
							if (arrangement.Ensemble !== "Mixed Choir/A Cappella" && arrangement.Visibility !== "Public")
								return null;

							return <tr key={arrangement.Title}>
								<td>{arrangement.Title}</td>
								<td>{arrangement.By}</td>
								<td>{arrangement.Parts}</td>
								<td><a href={arrangement.Gumroad} target="_blank" rel="noopener noreferrer">Purchase</a></td>
								<td><a href={arrangement.Youtube} target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube icon-link" aria-label="Youtube Link"/></a> | <a href={arrangement.Spotify} target="_blank" rel="noopener noreferrer"><i className="fa fa-spotify icon-link" aria-label="Spotify Link"/></a></td>
							</tr>
						})}
					</tbody>
				</table>
				} */}
			</div>
		</div>
	)
}

export default Arrangements;
