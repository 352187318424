import React from 'react';

import './ImageOverlay.css';

import Spotify from '../Pages/Spotify';

const ImageOverlay = ({ src, alt, arrangement }) => {
  const [isImageLoaded, setImageLoaded] = React.useState(false);
  const [flipped, setFlipped] = React.useState(false);

  const pauseAllAudio = () => {
    let audioPlayers = document.querySelectorAll('audio');
    for (let i = 0; i < audioPlayers.length; i++) {
      audioPlayers[i]?.pause();
    }
  }

  return (
    <div
      className="image-container"
      style={{ visibility: isImageLoaded ? 'visible' : 'hidden' }}
      onClick={() => {
        // Only way to unflip is to press the exit button
        if (flipped)
          return;

        pauseAllAudio();
        setFlipped(!flipped);
      }}
    >
      <img
        src={src}
        alt={alt}
        onLoad={() => setImageLoaded(true)}
        onError={() => console.log(`Failed to load image ${alt}`)}
      />

      {arrangement.SpecialText &&
      <div className="top-overlay">
        <div>{arrangement.SpecialText}</div>
      </div>
      }

      <div className="bottom-overlay">
        <h3 className="songTitle">{arrangement.Title}</h3>
        <div><em>{arrangement.By}</em></div>
        <div>{arrangement.Parts}</div>
      </div>

      {flipped &&
      <div className="overlay">
        <div className="exit-container" onClick={() => setFlipped(false)}>
          <i className="fa fa-times-circle exit-icon" aria-label="Toggle Song Info"/>
        </div>

        {arrangement.mp3 &&
        <div className="html-audio-container">
          <audio controls autoPlay controlsList="nodownload noplaybackrate nomute notimeremaining">
            <source src={`${process.env.PUBLIC_URL}/static/audio/${arrangement.mp3}`} type="audio/mp3" />
          </audio>
        </div>
        }

          {/*

          <Spotify link={arrangement.Spotify} />

          <div className="icon-container">
            <a href={arrangement.Youtube} target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube icon" aria-label="Youtube Link"/></a><a href={arrangement.Spotify} target="_blank" rel="noopener noreferrer"><i className="fa fa-spotify icon" aria-label="Spotify Link"/></a>
          </div> */}
          <div className="main-content">
            <p className="arrangement-desc">{arrangement.Description}</p>
            <a href={arrangement.Gumroad} target="_blank" rel="noopener noreferrer">
              <button className="purchase-button" onClick={pauseAllAudio}><span className="buttonText">PURCHASE</span></button>
            </a>
          </div>
        </div>
      }

      {/* <div className="overlay">
          <div className="icon-container">
            <a href={arrangement.Youtube} target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube icon" aria-label="Youtube Link"/></a><a href={arrangement.Spotify} target="_blank" rel="noopener noreferrer"><i className="fa fa-spotify icon" aria-label="Spotify Link"/></a>
          </div>

          <h1>{arrangement.Title}</h1>
          <h2 className="opb">{arrangement.By}</h2>
          <div>{arrangement.Parts}</div>
          <p><a href={arrangement.Gumroad} target="_blank" rel="noopener noreferrer">Purchase</a></p>
        </div> */}
    </div>
  );
};

export default ImageOverlay;
