import React from 'react';

import './css/Reset.css';
import './css/App.css';

import Main from './Components/MainNavigator';
import { Footer, MusicFooter } from './Components/footer';
import { Header, MusicHeader } from './Components/header';

const App = () => {
  // const teachingPaths = ['/', '/teaching', '/lessonpolicy', '/products', '/resume'];
  const musicPaths = ['/music', '/arrangements'];
  const isMusicView = musicPaths.includes(window.location.pathname);

  return (
    <div className="backgroundContainer">
      <div className="appContainer">
        {isMusicView ? <MusicHeader /> :  <Header/>}

        <Main/>

        {isMusicView ? <MusicFooter /> :  <Footer/> }
      </div>
    </div>
  )
}

export default App;
